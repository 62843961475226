import React from 'react';

import Text from '../../../ui/Text/Text';

import expert from '../../../assets/media/universal-expert.png';

import './universalExpert.scss';

const spheres = [
  { id: 0, text: 'поиск идей для бизнеса' },
  { id: 1, text: 'достижение целей' },
  { id: 2, text: 'маркетинг' },
  { id: 3, text: 'продажи' },
  { id: 4, text: 'подбор кадров' },
  { id: 5, text: 'управление финансами' },
  { id: 6, text: 'разработка стратегии' },
  { id: 7, text: 'расширение бизнеса' },
];

const UniversalExpert = () => (
  <section className='universal-expert'>
    <div className='container'>
      <div className='universal-expert-holder'>
        <Text variant='h3' className='section-title' fontWeight={600}>
          РАЗБОР ПРОВЕДЕТ УНИВЕРСАЛЬНЫЙ ЭКСПЕРТ
        </Text>
        <div className='expert'>
          <div className='img'>
            <img src={expert} alt='' />
          </div>
          <div className='content'>
            <Text variant='subtitle1' className='content-title' fontWeight={500}>
              Задавай ему вопросы на любые темы:
            </Text>
            <div className='question-spheres'>
              {spheres.map((sphere) => (
                <div key={sphere.id} className='sphere-item'>
                  <Text variant='subtitle3'>{sphere.text}</Text>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default UniversalExpert;
