import React, { useEffect, useRef, useState } from 'react';

import Text from '../../ui/Text/Text';

import './carousel.scss';

import mainImage from '../../assets/media/carousel/main-image.png';
import leftImage from '../../assets/media/carousel/left-text.png';
import rightImage from '../../assets/media/carousel/right-text.png';

type TKeys = 'honesty' | 'quality' | 'result' | 'progress' | 'target' | 'motivation';
type TLabelItem = {
  title: string;
  description: string;
  deg: number;
  next: TKeys;
};
type TConfig = Record<TKeys, TLabelItem>;

const config: TConfig = {
  honesty: {
    title: 'Честность',
    description: 'оказание качественных услуг наш приоритет ',
    deg: -12,
    next: 'quality',
  },
  quality: {
    title: 'Качество',
    description: 'мы честны с каждым учеником',
    deg: 56,
    next: 'result',
  },
  result: {
    title: 'Результат ',
    description: 'мы ориентируемся на результат каждого ученика',
    deg: 117,
    next: 'progress',
  },
  progress: {
    title: 'Прогресс ',
    description: 'мы следим за прогрессом наших учеников',
    deg: 154,
    next: 'target',
  },
  target: {
    title: 'Цель ',
    description: 'мы помогаем ученикам добиваться своих целей',
    deg: 226,
    next: 'motivation',
  },
  motivation: {
    title: 'Мотивация',
    description: 'мы изучаем типы личностей и мотивируем учеников',
    deg: 290,
    next: 'honesty',
  },
};

const states: TKeys[] = ['honesty', 'quality', 'result', 'progress', 'target', 'motivation'];

const Carousel = () => {
  const [step, setStep] = useState<TKeys>('honesty');
  const { title, description } = config[step];
  const [rotateDeg, setRotateDeg] = useState(-12);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const rotateCircle = (newSlide: TKeys) => {
    const currentSlideDeg = config[step].deg;
    const newSlideDeg = config[newSlide].deg;

    let incrementValue = 0;
    if (newSlideDeg > currentSlideDeg) {
      incrementValue = newSlideDeg - currentSlideDeg;
    } else {
      incrementValue = 360 - (currentSlideDeg - newSlideDeg);
    }
    setRotateDeg((prev) => prev + incrementValue);
  };

  const changeSlide = (newSlide?: TKeys) => {
    if (newSlide) {
      setStep(newSlide);
      rotateCircle(newSlide);
    }
  };

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      changeSlide(config[step].next);
    }, 2000);
  };

  useEffect(() => {
    resetTimer();
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [step]);

  return (
    <section className='carousel'>
      <Text variant='h3' fontWeight={600} className='title'>
        Наши ценности
      </Text>
      <div className='container'>
        <div className='carousel-holder'>
          <img src={leftImage} alt='' className='imageLeft' />
          <img src={rightImage} alt='' className='imageRight' />
          <div className='carousel-widget'>
            <div
              className={`circle ${step}`}
              style={{ transform: `translate(-50%, -50%) rotate(${rotateDeg}deg)` }}
            />
            {states.map((item) => {
              const { title: itemTitle } = config[item];
              const active = item === step ? 'active' : '';
              return (
                <div
                  key={item}
                  className={`pill ${item} ${active}`}
                  onClick={() => changeSlide(item)}
                  role='presentation'
                >
                  <Text variant='body1' fontWeight={500} className='label'>
                    {itemTitle}
                  </Text>
                </div>
              );
            })}
            <div className={`state-info ${step}`}>
              <span className='title'>
                <Text variant='h4' fontWeight={600} className='label'>
                  {title}
                </Text>
              </span>
              <Text variant='body1' fontWeight={400} className='description'>
                {description}
              </Text>
            </div>
          </div>
          <div className='image-holder'>
            <img src={mainImage} alt='' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Carousel;
