import React from 'react';

import InfoWidget from '../InfoWidget';

import bannerImageA from '../../../assets/media/girl-in-hoodie.png';
import bannerImageB from '../../../assets/media/banner-image-guy.png';

import { configA, configB, configC } from './constants';

export default function FeaturesWidgetDesktop() {
  return (
    <div className='features-widget'>
      <div className='column-left'>
        <div className='row1'>
          <div className='row1col1'>
            <InfoWidget
              label='Для тех, кто хочет открыть бизнес, но не знает, с чего начать'
              className='widget1'
            />
            <InfoWidget config={configA} styleType='typeA' className='widget2' />
          </div>
          <div className='row1col2'>
            <InfoWidget config={configB} styleType='typeB' className='widget3' />
          </div>
        </div>
        <div className='row2'>
          <div className='image-frame-a'>
            <img src={bannerImageA} alt='' className='banner-image' />
          </div>
          <div className='row2col2'>
            <InfoWidget config={configC} styleType='typeC' className='widget2' />
          </div>
        </div>
      </div>
      <div className='column-right'>
        <div className='info-wrapper'>
          <div className='image-frame-b'>
            <img src={bannerImageB} alt='' className='banner-image' />
          </div>
          <InfoWidget
            label='Для тех, кто открывал бизнес, но не достиг целей'
            className='widget4 customInfoWrapper1'
          />
          <InfoWidget
            label='Для тех, кто открыл бизнес и хочет масштаба'
            className='widget4 customInfoWrapper2'
          />
        </div>
      </div>
    </div>
  );
}
